<template>
  <v-card>
    <v-toolbar
      dark
      color="primary"
      dense
      class="mb-5"
    >
      <v-btn
        icon
        dark
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Compliance</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-form ref="form">
      <v-row
        justify="start"
        no-gutters
        class="pa-2"
      >
        <v-col
          class="d-flex"
          lg="2"
          md="2"
        >
          <v-checkbox
            v-model="compliance.active"
            label="Ativo?"
          />
        </v-col>
      </v-row>

      <v-row
        justify="start"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col
          class="d-flex pr-5"
          lg="6"
          md="12"
          sm="12"
        >
          <v-text-field
            v-model="compliance.author"
            outlined
            :rules="$rules.required"
            required
            dense
            label="Autor *"
          />
        </v-col>

        <v-col
          class="d-flex pr-5"
          lg="6"
          md="12"
          sm="12"
        >
          <v-select
            v-model="compliance.categoryId"
            :items="complianceCategory"
            :rules="$rules.required"
            outlined
            dense
            required
            item-text="title"
            item-value="id"
            label="Categoria *"
            class="form-control"
          />
        </v-col>

        <v-col
          class="d-flex pr-5"
          lg="6"
          md="12"
          sm="12"
        >
          <v-text-field
            v-model="compliance.headline"
            :counter="200"
            outlined
            dense
            label="Título *"
            :rules="$rules.required"
            required
          />
        </v-col>

        <v-col
          class="d-flex pr-5"
          lg="6"
          md="12"
          sm="12"
        >
          <v-text-field
            v-model="compliance.caption"
            outlined
            :counter="300"
            dense
            label="Subtítulo *"
            :rules="$rules.required"
            required
          />
        </v-col>

        <v-row
          justify="center"
          align="center"
        >
          <v-col
            class="d-flex pr-5"
            lg="12"
            md="12"
            sm="12"
          >
            <v-file-input
              v-model="imagesUpload"
              outlined
              label="Clique para adicionar imagens do tamanho 1024x768"
              prepend-icon="mdi-camera"
              required
              accept="image/jpeg, image/png"
              @change="onFilesSelected"
            />
          </v-col>
          <v-col class="pb-10">
            <gallery
              :images="imagesPreview"
              :rules="$rules.required"
              required
              @image-deleted="onDeleteImage"
            />
          </v-col>
        </v-row>
        <v-dialog
          v-model="dialogCropImage"
          persistent
        >
          <v-card>
            <div>
              <v-card-title class="text-h5">
                Recorte da imagem para tamanho 1024x768.
              </v-card-title>
            </div>
            <div>
              <v-card-text>
                Para melhor visualização da imagem no Informa Site, recomendamos
                utilizar o tamanho da imagem em 1024x768. <br>
                Utilize o "scroll" do mouse para ajustar a imagem.
                <br>
                <br>
                Clique e segure na imagem para arrastar e posicionar.
              </v-card-text>
            </div>
            <div style="padding-left: 600px">
              <resize-image
                ref="image"
                :target-width="resizeImageParams.targetWidth"
                :target-height="resizeImageParams.targetHeight"
                :min-aspect-ratio-value1="resizeImageParams.minAspectRatioValue1"
                :min-aspect-ratio-value2="resizeImageParams.minAspectRatioValue2"
                :max-aspect-ratio-value1="resizeImageParams.maxAspectRatioValue1"
                :max-aspect-ratio-value2="resizeImageParams.maxAspectRatioValue2"
                :image-param="imagesPreview"
              />
            </div>
            <v-card-actions>
              <v-btn
                color="red darken-1"
                text
                @click="cancelCropImage()"
              >
                Cancelar
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                @click="cropImageOnConfirm"
              >
                Confirmar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-col
          class="pr-5"
          lg="12"
          md="12"
          sm="12"
        >
          <kosmo-editor
            v-model="compliance.text"
            label="Texto do Compliance *"
            :rules="$rules.required"
            required
          />
        </v-col>
      </v-row>
    </v-form>

    <v-row
      justify="start"
      no-gutters
      class="pa-2 mt-6"
    >
      <v-col class="pr-5">
        <v-file-input
          v-model="attcUpload"
          label="Clique para adicionar novos arquivos"
          multiple
          outlined
          show-size
          counter
          accept="application/pdf,application/vnd.ms-excel"
        >
          <template #selection="{ index, text }">
            <v-chip
              v-if="index < 4"
              class="white accent-4"
              label
            >
              {{ text }}
            </v-chip>
            <span
              v-else-if="index === 4"
              class="text-overline grey--text text--darken-3 mx-2"
            >
              +{{ attcUpload.length - 4 }} arquivo(s)
            </span>
          </template>
        </v-file-input>
      </v-col>
    </v-row>
    <v-row
      v-if="attcFile"
    >
      <attachment-card :attachment="attcFile" />
    </v-row>
    <v-row
      class="mt-10"
      justify="center"
      no-gutters
    >
      <v-card-actions>
        <v-btn
          color="secondary"
          @click="close"
        >
          Cancelar
        </v-btn>
        <v-btn
          v-if="loading === true"
          color="primary"
          loading
          @click="openModal"
        />
        <v-btn
          v-else
          color="primary"
          @click="openModal"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-row>
    <v-dialog
      v-model="modalConfirm"
      class="d-flex pr-5"
      width="25%"
    >
      <v-card>
        <v-card-title class="text-h5">
          Novo Anexo
        </v-card-title>
        <v-card-text
          class="black--text"
        >
          Novo anexo selecionado. O que você deseja fazer com ele?<br><br>Substituir todos os anexos existentes<br>Adicioná-lo aos anexos existentes
        </v-card-text>
        <v-card-actions
          class="d-flex justify-space-around"
        >
          <v-btn
            color="primary"
            variant="text"
            @click="submit"
          >
            Substituir
          </v-btn>
          <v-btn
            class="white--text"
            color="#388E3C"
            variant="text"
            @click="submitUpdate"
          >
            Adicionar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import sanitize from 'sanitize-filename';
import { createFeaturedPhoto } from '@/services/photos-service';
import ComplienceService from '@/services/compliance.service';
import ComplianceCategoryService from '../../../services/compliance-category.service';
import { image } from '../../../services/upload-service';
import ResizeImage from '../../../components/shared/ResizeImage.vue';
import imageFormater from '../../../utils/convert-image-to-file';

export default {
  name: 'ComplianceForm',

  components: {
    ResizeImage,
    'attachment-card': () => import('../../../components/shared/AttachmentCards.vue'),
  },

  props: {
    populateWith: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      unities: ['Cassems Sede', 'teste'],
      compliance: {},
      linkFiles: [],
      dialogCropImage: false,
      imagesPreview: [],
      imagesUpload: null,
      updateButton: false,
      complianceCategory: [],
      loading: false,
      attcUpload: [],
      attcFile: [],
      resizeImageParams: {
        targetWidth: 1024,
        targetHeight: 768,
        minAspectRatioValue1: 4,
        minAspectRatioValue2: 3,
        maxAspectRatioValue1: 4,
        maxAspectRatioValue2: 3,
      },
      modalConfirm: false,
    };
  },
  async created() {
    this.attcFile = [];
    if (this.populateWith.id) {
      const { featuredImage, category } = this.populateWith;
      this.imagesPreview = featuredImage ? [featuredImage] : [];
      this.compliance = { ...this.populateWith };
      this.compliance.categoryId = category.id;
      this.attcFile = this.compliance?.attc ? [...this.compliance.attc] : [];
      this.updateButton = true;
    }
    this.loadComplianceCategories();
  },
  methods: {
    openModal() {
      if (this.populateWith.id) {
        if (this.attcUpload.length === 0 || this.attcFile.length === 0) {
          this.submitUpdate();
        } else {
          this.modalConfirm = true;
        }
      } else {
        this.submit();
      }
    },

    close() {
      this.$emit('close-dialog');
    },

    onFilesSelected(files) {
      this.dialogCropImage = true;
      this.imagesPreview = [URL.createObjectURL(files)];
    },

    onDeleteImage(index) {
      this.imagesPreview.splice(index, 1);
      this.imagesUpload = null;

      this.compliance = { ...this.compliance, featuredImage: null };
    },

    async checkImagesUploadAndCreatePhoto() {
      if (this.imagesUpload) {
        const imageType = imageFormater.getMimeType(this.imagesPreview);
        const formattedTitle = sanitize(this.compliance.headline);
        const imageFormatted = imageFormater.convertImageToFile(
          this.imagesPreview,
          `${formattedTitle.substring(0, 10)}.${imageType}`,
        );
        const photo = await createFeaturedPhoto(imageFormatted);
        this.compliance = { ...this.compliance, featuredImage: photo.link };
      }
    },

    async checkFileUpload() {
      const uploadPromises = this.attcUpload.map(async (item) => image(item));
      const uploadResponse = await Promise.all(uploadPromises);
      const arrayOfLinks = uploadResponse.map((item) => item[0]);
      this.compliance = {
        ...this.compliance,
        attc: arrayOfLinks,
      };
    },

    cropImageOnConfirm() {
      const { coordinates, canvas } = this.$refs.image.$refs.cropper.getResult();
      this.coordinates = coordinates;
      const imageToArray = canvas.toDataURL();
      this.imagesPreview = [imageToArray];
      this.dialogCropImage = false;
    },

    cancelCropImage() {
      this.dialogCropImage = false;
      this.imagesUpload = null;
      this.imagesPreview = [];
    },

    async updateCompliance() {
      try {
        this.loading = true;
        await this.checkImagesUploadAndCreatePhoto();
        await this.checkFileUpload();
        await ComplienceService.update({ ...this.compliance });
        this.$emit('compliance-edited', this.compliance);
        this.$toast.success('Compliance editado com sucesso');
        this.loading = false;
        this.close();
      } catch (e) {
        this.$handleHttpError(e);
        this.loading = false;
      }
    },

    async submit() {
      if (!this.$refs.form.validate()) {
        this.$toast.error('Verifique os campos e tente novamente');
        return;
      }

      if (this.populateWith.id) {
        await this.updateCompliance();
        return;
      }

      try {
        this.loading = true;
        await this.checkImagesUploadAndCreatePhoto();
        await this.checkFileUpload();
        await ComplienceService.create({ ...this.compliance });
        this.$emit('compliance-added', { ...this.compliance });
        this.clearForm();
        this.$toast.success('Compliance cadastrada com sucesso');
        this.loading = false;
        this.close();
      } catch (e) {
        this.$handleHttpError(e);
        this.loading = false;
      }
    },

    async checkFileNewAttch() {
      const uploadPromises = this.attcUpload.map(async (item) => image(item));
      const uploadResponse = await Promise.all(uploadPromises);
      const arrayOfLinks = uploadResponse.map((item) => item[0]);
      let oldLinks = this.attcFile.map((attc) => attc.link);
      oldLinks = oldLinks.concat(arrayOfLinks);
      this.compliance = {
        ...this.compliance,
        attc: oldLinks,
      };
    },

    async submitUpdate() {
      if (!this.$refs.form.validate()) {
        this.$toast.error('Verifique os campos e tente novamente');
        return;
      }

      try {
        this.loading = true;
        await this.checkImagesUploadAndCreatePhoto();
        await this.checkFileNewAttch();
        await ComplienceService.update({ ...this.compliance });
        this.$emit('compliance-edited', this.compliance);
        this.$toast.success('Compliance editado com sucesso');
        this.loading = false;
        this.close();
      } catch (e) {
        this.$handleHttpError(e);
        this.loading = false;
      }
    },

    clearForm() {
      this.$refs.form.reset();
      this.compliance.text = null;
      this.imagesPreview = [];
      this.$refs.form.resetValidation();
      this.attcUpload = [];
      this.attcFile = [];
    },

    async loadComplianceCategories() {
      this.complianceCategory = await ComplianceCategoryService.getCategory();
    },
  },
};
</script>
